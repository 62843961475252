<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="user_uuid"
                :label="$t('user')"
                class="autocomplete-new-entity"
                :class="form.model_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getUserKey()"
                  v-loading="getUserKey() === 0"
                  v-model="form.user_uuid"
                  :remote-method="searchUsers"
                  :placeholder="$t('select_an_user')"
                  remote
                  filterable
                  autocomplete
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchUsers(form.user)"
                  style="width: 100%"
                  @change="$emit('input'), onUserChange($event)"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewUserDialog = true"
                />
                <Icons
                  v-if="form.user_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/configuration/users', {
                      uuid: form.user_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="document_type" :label="$t('document_type')">
                <ElSelect
                  v-model="form.document_type_country"
                  placeholder=""
                  style="width: 70px; display: inline-block"
                  class="prepend"
                  filterable
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                >
                  <template #prefix>
                    <img
                      v-if="selectedDocumentTypeCountry"
                      :src="selectedDocumentTypeCountry.flag"
                    />
                  </template>
                  <ElOptionGroup
                    v-for="(group, index) in countries"
                    :key="index"
                  >
                    <ElOption
                      v-for="item in group.options"
                      :label="`${item.label}`"
                      :value="item.iso_code"
                      :key="item.iso_code"
                    >
                      <template #default>
                        <img :src="item.flag" :alt="item.iso_code" />
                        <span>{{ item.iso_code }}</span>
                      </template>
                    </ElOption>
                  </ElOptionGroup>
                </ElSelect>
                <ElSelect
                  v-model="form.document_type"
                  :placeholder="$t('select_a_document_type')"
                  style="width: calc(100% - 70px); display: inline-block"
                  class="append"
                  filterable
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                >
                  <ElOption
                    v-for="item in documentTypesArray"
                    :label="`${t(
                      'document_types.' + item.label.toLowerCase()
                    )}`"
                    :value="item.value"
                    :key="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="document_number" :label="$t('document_number')">
                <ElInput
                  v-model="form.document_number"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewUserDialog"
      :width="width <= 600 ? '100%' : '400px'"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_user') }}
        </span>
      </template>
      <QuickCreateUserDialog
        @create=";(showNewUserDialog = false), createUser($event)"
        @cancel="showNewUserDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_EMPLOYEE from '~/graphql/employees/mutation/createEmployee.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  document_type_country: '',
  document_type: '',
  document_number: '',
  user_uuid: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()
let { newWindowItem } = getTableInitialVariables()

const ruleFormRef = ref()
const loading = ref(false)
const documentTypes = getDocumentTypes()
const countries = getModelCountries()
const showNewUserDialog = ref(false)

const documentNumberElForm = (rule, value, callback) => {
  if (!documentNumber(value, form)) {
    return callback(t('document_number_not_valid'))
  }
  return callback()
}

onMounted(() => {
  initialUsers()
})

const inputRules = reactive({
  user_uuid: [
    {
      required: true,
      message: t('user_is_required'),
      trigger: 'blur'
    }
  ],
  document_type: [
    {
      required: true,
      message: t('document_type_is_required'),
      trigger: 'blur'
    }
  ],
  document_type_country: [
    {
      required: true,
      message: t('document_type_country_is_required'),
      trigger: 'blur'
    }
  ],
  document_number: [
    {
      required: true,
      message: t('document_number_is_required'),
      trigger: 'blur'
    },
    { validator: documentNumberElForm, trigger: ['blur', 'change'] }
  ]
})

const documentTypesArray = computed(() => {
  if (['ES'].includes(form.document_type_country)) {
    return getDocumentTypes()
      .filter((i) => i.iso_code === form.document_type_country)
      .filter((i) => i !== null)
  }
  if (!['ES'].includes(form.document_type_country)) {
    return getDocumentTypes()
      .filter((i) => i.iso_code !== 'ES')
      .filter((i) => i !== null)
  }
  return getDocumentTypes()
    .filter((i) => i.iso_code === 'ES')
    .filter((i) => i !== null)
})

const selectedDocumentTypeCountry = computed(() => {
  for (const elem of countries) {
    const country = elem.options.find(
      (item) => item.iso_code === form.document_type_country
    )
    if (country) {
      return country
    }
  }
  return null
})

const rules = {
  user_uuid: { required },
  document_type: { required },
  document_type_country: { required },
  document_number: { required, documentNumber }
}

const v$ = useVuelidate(rules, form)

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const variables = {
    user_uuid: form.user_uuid,
    document_type: form.document_type,
    document_number: form.document_number,
    document_type_country: form.document_type_country,
    enabled: true
  }
  const response = await mutation(CREATE_EMPLOYEE, variables)
  if (response.error) {
    if (response.error.message.includes('input.document number')) {
      $showError(t('document_number_already_exists'))
    } else {
      $showError(response.error, t)
      $sentry(response.error, 'create')
    }
    loading.value = false
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createEmployee ?? null)
    loading.value = false
  }
}

const onUserChange = async (uuid) => {
  if (getSearchUsers()?.length && uuid) {
    const user = getSearchUsers().find((item) => item.value === uuid)
    const results = await usersAutocomplete({
      search: user?.label?.split(' ')[0] ?? ''
    })
    if (results?.getAllUsers?.data?.find((item) => item.uuid === uuid)) {
      const item = results.getAllUsers.data.find((item) => item.uuid === uuid)
      form.first_name = item.first_name
      form.last_name = item.last_name
    }
  }
}
</script>

<style>
</style>